import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useEffect, useState } from "react";
import Home from "./pages/home";
import MyLearings from "./pages/my-learnings";
import Lab from "./pages/lab";
import Login from "./pages/Login/Login.js";
import CourseDetail from "./pages/CourseDetail/CourseDetail.js";
import { getAccessToken } from "./services/apiService.js";
import Course from "./pages/course/index.js";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Check if the user is already logged in (i.e., accessToken is present)
    const token = getAccessToken();
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const handleLogin = (status) => {
    setIsLoggedIn(status);
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/courses" element={<Course />} />
        <Route
          path="/login"
          element={
            isLoggedIn ? <Navigate to="/" /> : <Login onLogin={handleLogin} />
          }
        />
        <Route
          path="/my-courses"
          element={isLoggedIn ? <MyLearings /> : <Navigate to="/login" />}
        />
        <Route
          path="/lab"
          element={isLoggedIn ? <Lab /> : <Navigate to="/login" />}
        />
        <Route
          path="/course-detail"
          element={isLoggedIn ? <CourseDetail /> : <Navigate to="/login" />}
        />
      </Routes>
    </Router>
  );
}

export default App;
