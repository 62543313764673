import React from "react";
import { useNavigate } from "react-router-dom";
import {
  FaBook,
  FaFlask,
  FaClipboardList,
  FaCheckCircle,
} from "react-icons/fa";

const CourseDetail = () => {
  const navigate = useNavigate();

  return (
    <div className="p-8 max-w-6xl mx-auto">
      {/* Current Module Section */}
      <section className="mb-10">
        <h2 className="text-3xl font-bold mb-6">Current Module</h2>
        <div className="border p-6 bg-gray-100 rounded-lg">
          <h3 className="text-2xl font-semibold mb-4">Introduction</h3>
          <ul className="list-disc list-inside mb-6">
            <li>CompTIA Introduction</li>
            <li>Course Details</li>
          </ul>
          <div className="flex space-x-4">
            <button
              className="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600"
              onClick={() => navigate("/book")}
            >
              Book
            </button>
            <button
              className="bg-green-500 text-white px-6 py-2 rounded hover:bg-green-600"
              onClick={() => navigate("/lab")}
            >
              Go to Lab
            </button>
            <button
              className="bg-yellow-500 text-white px-6 py-2 rounded hover:bg-yellow-600"
              onClick={() => navigate("/assessment")}
            >
              Start Assessment
            </button>
          </div>
        </div>
      </section>

      {/* About This Course Section */}
      <section className="mb-10">
        <h2 className="text-3xl font-bold mb-6">About This Course</h2>
        <p className="text-lg text-gray-700 mb-6">
          This course is designed to provide you with all the necessary skills
          and knowledge to succeed in the field. You will learn from
          comprehensive materials, engage in hands-on labs, and complete
          assessments to ensure that you master each topic. The course is
          structured to take you through both fundamental and advanced concepts,
          offering in-depth learning experiences.
        </p>
        <div className="flex space-x-6">
          <div className="flex items-center space-x-2">
            <FaCheckCircle className="text-blue-500" size={24} />
            <span className="text-lg">Comprehensive Learning</span>
          </div>
          <div className="flex items-center space-x-2">
            <FaCheckCircle className="text-blue-500" size={24} />
            <span className="text-lg">Hands-On Labs</span>
          </div>
          <div className="flex items-center space-x-2">
            <FaCheckCircle className="text-blue-500" size={24} />
            <span className="text-lg">Expert Guidance</span>
          </div>
        </div>
      </section>

      {/* Course Content Section */}
      <section className="mb-10">
        <h2 className="text-3xl font-bold mb-6">Course Content</h2>
        <ul className="list-disc list-inside text-lg">
          <li>Module 1: Introduction to Networking</li>
          <li>Module 2: Cybersecurity Essentials</li>
          <li>Module 3: Cloud Computing Overview</li>
          <li>Module 4: Practical Labs and Assessments</li>
        </ul>
      </section>

      {/* Review Section */}
      <section>
        <h2 className="text-3xl font-bold mb-6">Reviews</h2>
        <div className="space-y-6">
          <div className="bg-gray-100 p-6 rounded-lg">
            <p className="text-lg">
              <strong>John Doe</strong> - "This course provided an excellent
              introduction to networking and cybersecurity. Highly recommended!"
            </p>
          </div>
          <div className="bg-gray-100 p-6 rounded-lg">
            <p className="text-lg">
              <strong>Jane Smith</strong> - "The hands-on labs really helped
              solidify my understanding of the concepts. The assessments were
              well thought out."
            </p>
          </div>
          <div className="bg-gray-100 p-6 rounded-lg">
            <p className="text-lg">
              <strong>Michael Johnson</strong> - "Great course with valuable
              materials and support. The instructors were very knowledgeable."
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CourseDetail;
