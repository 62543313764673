import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import CourseCard from "../../components/course-card";
import { getAllResources } from "../../services/apiService";

const Course = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const loadCourses = async () => {
    try {
      const response = await getAllResources();

      if (response) {
        console.log(response);
        const mappedCourses = response.data.map((item) => ({
          key: item.id,
          name: item.resourceName, 
          tag: "Beginner",
        }));

        setCourses(mappedCourses);
      }
      setLoading(false);
    } catch (err) {
      console.error("Error loading courses:", err);
      setError("Failed to load courses.");
      setLoading(false);
    }
  };

  useEffect(() => {
    loadCourses();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>No resources found</div>;
  }

  return (
    <div>
      <Navbar />
      <div className="w-2/3 mx-auto">
        {/* <Carousel autoplay>
          <div>
            <img src={banner1} alt="" />
          </div>
        </Carousel> */}
        <div className="my-20">
          <h1 className="text-4xl">
            My <span className="italic font-semibold">Courses</span>
          </h1>
          <div className="mt-10 grid grid-cols-3 gap-8">
            {courses.map((item) => (
              <CourseCard
                key={item.key}
                tag={item.tag}
                name={item.name}
                bougth={true}
              />
            ))}
          </div>
        </div>

        <div className="my-20">
          <h1 className="text-4xl">
            Explore <span className="italic font-semibold">Courses</span>
          </h1>
          <div className="mt-10 grid grid-cols-3 gap-8">
            {courses.map((item) => (
              <CourseCard
                key={item.key}
                tag={item.tag}
                name={item.name}
                bougth={true}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Course;
