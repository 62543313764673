import axios from "axios";
import { jwtDecode } from "jwt-decode";
import env from "react-dotenv";

const API_URL = env.REACT_APP_API_URL;

// Login request
export const login = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}`, {
      systemId: "USER",
      method_name: "signIn",
      params: { email, password },
    });

    if (response.data && response.data.access_token) {
      setAccessToken(response.data.access_token);
    }

    return response.data;
  } catch (error) {
    console.error("Error logging in:", error);
    throw error;
  }
};

// Access Token Management
export const getAccessToken = () => {
  return localStorage.getItem("accessToken");
};

export const setAccessToken = (token) => {
  localStorage.setItem("accessToken", token);
};

export const clearAccessToken = () => {
  localStorage.removeItem("accessToken");
};

const getUserIdFromToken = () => {
  const token = getAccessToken();
  if (!token) return null;

  const decoded = jwtDecode(token);
  return decoded?.id;
};

export const fetchCourses = async () => {
  try {
    const userId = getUserIdFromToken();

    if (!userId) {
      throw new Error("User ID not found in token.");
    }

    const response = await axios.post(`${API_URL}`, {
      systemId: "USER_RESOURCE_MAPPING",
      method_name: "findByUserId",
      params: { userId },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching courses:", error);
    throw error;
  }
};

export const getAllResources = async () => {
  try {
    const response = await axios.post(`${API_URL}`, {
      systemId: "AVAILABLE_RESOURCE",
      method_name: "getAllResources",
    });
    return response;
  } catch (error) {
    console.error("Error fetching resources:", error);
    throw error;
  }
};
